import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--title--container">
        <div className="about--section--subtitle">An Introduction</div>
        <h1 className="about--section--title">About Me</h1>
      </div>
      <div className="about--section--grid">
        <div className="about--section--content">
          <p className="about--section--description">
            I've recently graduated from the University of Oregon with a bachelor of science, majoring in business and economics, and a minor in legal studies. While taking classes, I joined Greek Life and was elected to the executive board of Phi-Delta-Theta, holding the Risk Management position. Along with 14 initial members, we were drawn to the organization through friendship, rectitude, and sound learning principles. Today, the fraternity has grown to over 65 active members and re-established affiliations with the university and parent organization. My position on the executive board allowed me a unique opportunity to create foundational policy and structure. Our goal on the executive board was to create a welcoming and safe organization for future members and set them up for success for years to come. I’m incredibly proud of my fraternity re-establishing on campus, our philanthropic efforts to our local community, and our fundraising efforts such as the <a href='https://www.livelikelou.org/'>Live Like Lou Foundation</a>.
          </p>
          <br />
          <p className="about--section--description">
            While in college I obtained a position at Amazon as a customer service associate. The role taught me essential people skills and built incredible professional relationships within the operations division. After learning the workflow of the position, I noticed a flaw in the returns process causing frustration for customers and associates. This tool reduced the gap between the customer's return and our internal systems recognizing the order, saving valuable time. It’s rewarding to make a difference for both my coworkers and Amazon customers by creating such a simple tool.
          </p>
          <br />
          <p className="about--section--description">
            During the pandemic, I learned how to program code and applied this skill by building servers. This ultimately led to the creation of online communities and projects such as tools created for Amazon and this website. The servers allow people all over the world to play games, have fun, and create new friendships which led to the discovery of what excites me most. I love finding opportunities that allow continuous learning and allow me to make fulfilling contributions. I’ve noticed through these servers how members gained a sense of community, just because of an interest in development. I find it extremely rewarding to hear positive feedback and It's motivating to see people find value through my projects.
          </p>
          <br />
          <p className="about--section--description">
            Wrapping things up, I’ll write more about my interests in the blog section, which you can find below. Some articles may consist of Artificial intelligence, traveling experiences, golfing, fishing, game development, stock markets, baseball, outdoor recreation, college athletics, Fitness, and my dog Teddy. I’d love to hear your thoughts and insights on these articles, feel free to continue the conversation in the contact section & on LinkedIn!
          </p>
          <div className="about--section--button">
            <a className="btn-link" href="https://www.linkedin.com/in/jgwynn8/">
              <button className="about--section--button-clean">
              <FontAwesomeIcon icon={faLinkedin} className="linkedin-icon" />
                Let’s Connect!
              </button>
            </a>
          </div>
        </div>
        <div className="about--section--img--content">
          <div className="about--section--img--wrapper stagger left">
            <img className="about--section--img" src="./img/grad.png" alt="Graduation picture" />
          </div>
          <div className="about--section--img--wrapper stagger right">
            <img className="about--section--img" src="./img/boat-picture.png" alt="Boat picture" />
          </div>
          <div className="about--section--img--wrapper stagger left">
            <img className="about--section--img" src="./img/teddy.png" alt="Picture of my dog, Teddy" />
          </div>
        </div>
      </div>
    </section>
  );
}
