import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import TOPOLOGY from 'vanta/src/vanta.topology';

export default function HeroSection() {
  const vantaRef = useRef(null);
  const vantaEffect = useRef(null);

  useEffect(() => {
    if (!vantaEffect.current) {
      vantaEffect.current = TOPOLOGY({
        el: "#vanta",
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.0,
        minWidth: 200.0,
        scale: 1.0,
        scaleMobile: 1.0,
        color: 0x3bdca3,
      });
    }
  }, []);

  return (
    <section
      id="heroSection"
      className="hero--section"
      ref={vantaRef}
      style={{ height: '100vh', width: '100vw' }}
    >
      <div className="bg" id="vanta"></div>
      <div className="hero--content">
        <div className="hero--content-box">
          <div className="hero--title">
            <img src="./img/name-sign.png" alt="John Gwynn" />
          </div>
          <div className="hero--description">Hello, welcome to my website..</div>
        </div>
        <div className="chevron--container">
          <div className="chevron"></div>
          <div className="chevron"></div>
          <div className="chevron"></div>
        </div>
      </div>
    </section>
  );
}