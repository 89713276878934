import React from 'react';
import './Modal.css';

const Modal = ({ show, onClose, blog }) => {
  if (!show) {
    return null;
  }

  const { title, image, excerpt, author, date, content } = blog;

  const paragraphs = content.split('\n');

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 512 512">
            <path fill="#f44e56" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
          </svg>
        </button>
        <h2>{title}</h2>
        <img src={image} alt={`Image for ${title}`} />
        <p>{excerpt}</p>
        <p>By {author} | {date}</p>
        <div className="modal-content-text">
          {paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modal;
