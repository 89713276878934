import { Link } from "react-scroll";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin, faXTwitter } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="footer--container">
      <div className="footer--link--container">
        <div className="nav--logo--name--container">
          <img className="nav--logo" width="75" height="75" src="./img/new.png" alt="Bloom Logo" />
          <img width="200"  height="50" src="./img/name-image.png" />
        </div>
        <div className="footer--items">
          <ul>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="heroSection"
                className="text-md"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="MyPortfolio"
                className="text-md"
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="AboutMe"
                className="text-md"
              >
                About Me
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to="Contact"
                className="text-md"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer--social--icon">
          <ul>
            <li>
              <a
                href="https://www.instagram.com/jxckgwynn/"
                className="navbar--content"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className="footer--social-icon" style={{ fontSize: "2rem", color: "var(--primary)" }} />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/ItsJackkG"
                className="navbar--content"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faXTwitter} className="footer--social-icon" style={{ fontSize: "2rem", color: "var(--primary)" }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/jgwynn8/"
                className="navbar--content"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} className="footer--social-icon" style={{ fontSize: "2rem", color: "var(--primary)" }} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr className="divider" />
      <div className="footer--content--container">
    </div>
    </footer>
  );
}

export default Footer;
